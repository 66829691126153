<template>
  <div class="index-page">
    <menuPage :active="activeNames" :menu-list="menuList" />
    <div class="button-fixed">
      <van-button type="info" block @click="$router.back()">
        返回
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'
import menuPage from '@/components/menu'
export default {
  name: 'Index',
  components: {
    [menuPage.name]: menuPage,
    [Button.name]: Button
  },
  data () {
    return {
      activeNames: [],
      menuList: [
        {
          menuName: '提货申请',
          isLink: false,
          icon: 'apps-o',
          menuUrl: '/delivery/apply',
          menuIcon: 'icon-tihuoshenqing'
        },
        {
          menuName: '提货记录',
          isLink: false,
          icon: 'apps-o',
          menuUrl: '/delivery/record',
          menuIcon: 'icon-tihuo'
        }
      ]
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>

</style>
