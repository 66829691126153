<template>
  <van-collapse ref="collapse" v-model="activeList" class="van-collapse-slot">
    <van-collapse-item v-for="item in menuList" :key="item.menuName" :title="item.menuName" :name="item.menuName" :is-link="item.isLink">
      <template #title>
        <div class="van-collapse-p" @click="collapseChange(item)">
          <span :class="'icon iconfont ' + item.menuIcon" />{{ item.menuName }}
        </div>
      </template>
      <van-cell-group>
        <van-cell v-for="item1 in item.childList" :key="item1.menuName" :title="item1.menuName" :to="item1.menuUrl" />
      </van-cell-group>
    </van-collapse-item>
  </van-collapse>
</template>

<script>
import { Collapse, CollapseItem, Cell, CellGroup, Icon } from 'vant'
export default {
  name: 'MenuPage',
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon
  },
  props: {
    active: {
      type: Array,
      default () {
        return []
      }
    },
    menuList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      activeList: this.active
    }
  },
  watch: {
    // active (newValue) {
    //   this.activeList = newValue
    // }
  },
  created () {
  },
  methods: {
    collapseChange (item) {
      if (!item.isLink) {
        this.$router.push({ path: item.menuUrl })
      }
    }
  }
}
</script>

<style>
  .iconfont{
    padding-right: 10px;
    vertical-align: middle;
  }
</style>
