var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-page" },
    [
      _c("menuPage", {
        attrs: { active: _vm.activeNames, "menu-list": _vm.menuList }
      }),
      _c(
        "div",
        { staticClass: "button-fixed" },
        [
          _c(
            "van-button",
            {
              attrs: { type: "info", block: "" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v(" 返回 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }