var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-collapse",
    {
      ref: "collapse",
      staticClass: "van-collapse-slot",
      model: {
        value: _vm.activeList,
        callback: function($$v) {
          _vm.activeList = $$v
        },
        expression: "activeList"
      }
    },
    _vm._l(_vm.menuList, function(item) {
      return _c(
        "van-collapse-item",
        {
          key: item.menuName,
          attrs: {
            title: item.menuName,
            name: item.menuName,
            "is-link": item.isLink
          },
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "van-collapse-p",
                        on: {
                          click: function($event) {
                            return _vm.collapseChange(item)
                          }
                        }
                      },
                      [
                        _c("span", { class: "icon iconfont " + item.menuIcon }),
                        _vm._v(_vm._s(item.menuName) + " ")
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "van-cell-group",
            _vm._l(item.childList, function(item1) {
              return _c("van-cell", {
                key: item1.menuName,
                attrs: { title: item1.menuName, to: item1.menuUrl }
              })
            }),
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }